import {Injectable} from '@angular/core';
import {configs2} from '@app/search/search.config';
import {BehaviorSubject} from 'rxjs';
import {FacetConfig} from '@app/models/search-new.model';
import {SiteIdService} from './site-id.service';
import {SearchDriver} from './search-driver';

@Injectable({
  providedIn: 'root',
})
export class SearchBarService2 {
  searchDriver: SearchDriver;
  config: FacetConfig;

  doSearch$ = new BehaviorSubject<boolean>(false);

  constructor(private siteIdService: SiteIdService) {
    this.config = {
      ...configs2[this.siteIdService.site.siteID].facetConfig,
    };
    this.searchDriver = new SearchDriver(this.config);
  }
}
