import {Site} from '@app/models';
import {SearchConfig} from '@app/models/search-new.model';
import {cruiseTypeLabelMap, facetBucketMap} from './facet-bucket-map';

export const configs2: {[key in Site]: SearchConfig} = {
  Alaska: {
    facetConfig: {
      facets: [
        {
          field: 'cruiseline_name',
          label: 'Cruise Line',
          type: 'standard',
          maxCount: 1,
          icon: 'helm',
          search: true,
        },
        {
          field: 'depart_month',
          label: 'Date',
          type: 'date',
          maxCount: 3,
          icon: 'calendar',
        },
        {
          field: 'duration',
          label: 'Length',
          type: 'standard',
          maxCount: 1,
          icon: 'suitcase',
          search: false,
          labels: facetBucketMap['duration'],
        },
        {
          field: 'min_price',
          label: 'Price',
          type: 'standard',
          maxCount: 1,
          icon: 'dollar-square',
          search: false,
          labels: facetBucketMap['min_price'],
          remove: ['14'], // remove ODY 999999 prices (call for price)
        },
        {
          field: 'depart_port',
          label: 'Depart. Port',
          type: 'standard',
          maxCount: 1,
          icon: 'anchor',
          search: true,
        },
        {
          field: 'ship_name',
          label: 'Ship',
          type: 'standard',
          maxCount: 1,
          icon: 'ship',
          search: true,
        },
        {
          field: 'cruise_type',
          label: 'Trip Type',
          type: 'standard',
          maxCount: 1,
          icon: 'ship',
          search: false,
          labels: cruiseTypeLabelMap,
        },
      ],
      permanentFilters: [
        {
          FacetName: 'dest_id',
          FacetValue: '2',
        },
      ],
    },
  },
  Azamara: {
    facetConfig: {
      facets: [
        {
          fields: ['dest_id', 'europe'],
          label: 'Destination',
          type: 'destination',
          maxCount: 1,
          icon: 'map',
        },
        {
          field: 'depart_month',
          label: 'Date',
          type: 'date',
          maxCount: 3,
          icon: 'calendar',
        },
        {
          field: 'duration',
          label: 'Length',
          type: 'standard',
          maxCount: 1,
          icon: 'suitcase',
          search: false,
          labels: facetBucketMap['duration'],
        },
        {
          field: 'min_price',
          label: 'Price',
          type: 'standard',
          maxCount: 1,
          icon: 'dollar-square',
          search: false,
          labels: facetBucketMap['min_price'],
          remove: ['14'], // remove ODY 999999 prices (call for price)
        },
        {
          field: 'depart_port',
          label: 'Depart. Port',
          type: 'standard',
          maxCount: 1,
          icon: 'anchor',
          search: true,
        },
        {
          field: 'ship_name',
          label: 'Ship',
          type: 'standard',
          maxCount: 1,
          icon: 'ship',
          search: true,
        },
      ],
      permanentFilters: [
        {
          FacetName: 'cruiseline_name',
          FacetValue: 'Azamara ', // match ody_cruise_lines
        },
        {
          FacetName: 'signature',
          FacetValue: 'true',
        },
      ],
    },
  },
  Oceania: {
    facetConfig: {
      facets: [
        {
          fields: ['dest_id', 'europe'],
          label: 'Destination',
          type: 'destination',
          maxCount: 1,
          icon: 'map',
        },
        {
          field: 'depart_month',
          label: 'Date',
          type: 'date',
          maxCount: 3,
          icon: 'calendar',
        },
        {
          field: 'duration',
          label: 'Length',
          type: 'standard',
          maxCount: 1,
          icon: 'suitcase',
          search: false,
          labels: facetBucketMap['duration'],
        },
        {
          field: 'min_price',
          label: 'Price',
          type: 'standard',
          maxCount: 1,
          icon: 'dollar-square',
          search: false,
          labels: facetBucketMap['min_price'],
          remove: ['14'], // remove ODY 999999 prices (call for price)
        },
        {
          field: 'depart_port',
          label: 'Depart. Port',
          type: 'standard',
          maxCount: 1,
          icon: 'anchor',
          search: true,
        },
        {
          field: 'ship_name',
          label: 'Ship',
          type: 'standard',
          maxCount: 1,
          icon: 'ship',
          search: true,
        },
      ],
      permanentFilters: [
        {
          FacetName: 'cruiseline_name',
          FacetValue: 'Oceania Cruises', // match ody_cruise_lines
        },
        {
          FacetName: 'signature',
          FacetValue: 'true',
        },
      ],
    },
  },
  Regent: {
    facetConfig: {
      facets: [
        {
          fields: ['dest_id', 'europe'],
          label: 'Destination',
          type: 'destination',
          maxCount: 1,
          icon: 'map',
        },
        {
          field: 'depart_month',
          label: 'Date',
          type: 'date',
          maxCount: 3,
          icon: 'calendar',
        },
        {
          field: 'duration',
          label: 'Length',
          type: 'standard',
          maxCount: 1,
          icon: 'suitcase',
          search: false,
          labels: facetBucketMap['duration'],
        },
        {
          field: 'min_price',
          label: 'Price',
          type: 'standard',
          maxCount: 1,
          icon: 'dollar-square',
          search: false,
          labels: facetBucketMap['min_price'],
          remove: ['14'], // remove ODY 999999 prices (call for price)
        },
        {
          field: 'depart_port',
          label: 'Depart. Port',
          type: 'standard',
          maxCount: 1,
          icon: 'anchor',
          search: true,
        },
        {
          field: 'ship_name',
          label: 'Ship',
          type: 'standard',
          maxCount: 1,
          icon: 'ship',
          search: true,
        },
      ],
      permanentFilters: [
        {
          FacetName: 'cruiseline_name',
          FacetValue: 'Regent Seven Seas ', // match ody_cruise_lines
        },
        {
          FacetName: 'signature',
          FacetValue: 'true',
        },
      ],
    },
  },
  Silversea: {
    facetConfig: {
      facets: [
        {
          fields: ['dest_id', 'europe'],
          label: 'Destination',
          type: 'destination',
          maxCount: 1,
          icon: 'map',
        },
        {
          field: 'depart_month',
          label: 'Date',
          type: 'date',
          maxCount: 3,
          icon: 'calendar',
        },
        {
          field: 'duration',
          label: 'Length',
          type: 'standard',
          maxCount: 1,
          icon: 'suitcase',
          search: false,
          labels: facetBucketMap['duration'],
        },
        {
          field: 'min_price',
          label: 'Price',
          type: 'standard',
          maxCount: 1,
          icon: 'dollar-square',
          search: false,
          labels: facetBucketMap['min_price'],
          remove: ['14'], // remove ODY 999999 prices (call for price)
        },
        {
          field: 'depart_port',
          label: 'Depart. Port',
          type: 'standard',
          maxCount: 1,
          icon: 'anchor',
          search: true,
        },
        {
          field: 'ship_name',
          label: 'Ship',
          type: 'standard',
          maxCount: 1,
          icon: 'ship',
          search: true,
        },
      ],
      permanentFilters: [
        {
          FacetName: 'cruiseline_name',
          FacetValue: 'Silversea ', // match ody_cruise_lines
        },
        {
          FacetName: 'signature',
          FacetValue: 'true',
        },
      ],
    },
  },
  Windstar: {
    facetConfig: {
      facets: [
        {
          fields: ['dest_id', 'europe'],
          label: 'Destination',
          type: 'destination',
          maxCount: 1,
          icon: 'map',
        },
        {
          field: 'depart_month',
          label: 'Date',
          type: 'date',
          maxCount: 3,
          icon: 'calendar',
        },
        {
          field: 'duration',
          label: 'Length',
          type: 'standard',
          maxCount: 1,
          icon: 'suitcase',
          search: false,
          labels: facetBucketMap['duration'],
        },
        {
          field: 'min_price',
          label: 'Price',
          type: 'standard',
          maxCount: 1,
          icon: 'dollar-square',
          search: false,
          labels: facetBucketMap['min_price'],
          remove: ['14'], // remove ODY 999999 prices (call for price)
        },
        {
          field: 'depart_port',
          label: 'Depart. Port',
          type: 'standard',
          maxCount: 1,
          icon: 'anchor',
          search: true,
        },
        {
          field: 'ship_name',
          label: 'Ship',
          type: 'standard',
          maxCount: 1,
          icon: 'ship',
          search: true,
        },
      ],
      permanentFilters: [
        {
          FacetName: 'cruiseline_name',
          FacetValue: 'Windstar', // match ody_cruise_lines
        },
        {
          FacetName: 'signature',
          FacetValue: 'true',
        },
      ],
    },
  },
};
